.toggleButton {
    display: block;
    height: 44px;
    width: 100%;
    font-size: 15px;
    overflow: hidden;
    text-transform: capitalize;
    background-color: #f5f3ee;
    font-weight: 400 !important;
    font-style: normal;
    border-color: #f5f3ee;
    color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    margin: 0px !important;
    line-height: 24px;
    border-radius: 8px;
    letter-spacing: 0 !important;
}

.toggleButton:hover {
    background-color: #f5f3ee;
    border-color: #4e7f5e;
    color: #4e7f5e;
    font-weight: 600 !important;
}

.toggleButtonSelected {
    background-color: #4e7f5e;
    border-color: #4e7f5e;
    color: #f5f3ee !important;
    font-weight: 700 !important;
}
