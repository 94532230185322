.navbar li {
    list-style-type: none;
    display: inline-block;
}

.navbar ul {
    text-align: center;
    display: flex;
    height: 100%;
    align-items: center;
    margin: 0%;
    padding: unset;
}

.navbar ul a {
    display: block;
    width: 8em;
    font-size: clamp(0.75rem, 0.4865rem + 1.0811vw, 1.25rem);
    font-weight: 600;
    line-height: 150%;
    color: #3d3939;
    text-decoration: none;
}

.navbar ul a:hover {
    color: #4e7f5e;
}

.navbar ul > li:first-child {
    margin-right: auto;
    text-align: left;
}

.navbar {
    background: white;
    height: 100px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;

    left: 50%;
    transform: translateX(-50%);
}

@media (max-width: 800px) {
    .hide_sm {
        display: none !important;
    }

    .navbar button {
        border-radius: 15px;
    }
}
