.container {
    z-index: -1;
    flex-grow: 1;
    overflow-y: visible;
    overflow-x: visible;
    height: 80vh;

    width: 40%;
    margin-left: -10%;
}

.innerContainer {
    height: 2500px;
    width: 2300px;
    transform: scale(0.35) translate3d(0, 0, 0);

    transform-origin: top left;

    animation: moveSlideshowDesktop 12s linear infinite;
}

.innerContainer > img {
    position: absolute;
    object-fit: contain;
}

@keyframes moveSlideshowDesktop {
    100% {
        transform: scale(0.35) translateY(-128.6666%);
    }
}

@keyframes moveSlideshowMobile {
    100% {
        transform: scale(0.25) translateY(-128.6666%);
    }
}

@keyframes moveSlideshowTablet {
    100% {
        transform: scale(0.28) translateY(-128.6666%);
    }
}

@media (max-width: 540px) {
    .container {
        height: 50vh;
        width: 100%;
        margin: auto;
    }

    .innerContainer {
        transform: scale(0.25);
        transform-origin: top left;

        animation: moveSlideshowMobile 12s linear infinite;

        margin-left: -20%;
    }
}
@media (max-width: 768px) {
    .innerContainer {
        transform: scale(0.28);
        animation: moveSlideshowTablet 12s linear infinite;
    }
}
