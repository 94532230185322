/* https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/ */

.heroSection {
    display: flex;
    padding-top: 100px;
    padding-bottom: 0px;
    position: relative;
    overflow: hidden;
}

.heroSection h1 {
    font-weight: 275;
    font-size: clamp(1.5rem, 0.2703rem + 5.045vw, 5rem);
    line-height: 105.2%;
    margin: 0rem;
    min-height: 3.5em;
}

.arrowAnimate {
    animation-name: arrow;
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
}

.productContainer {
    display: flex;
    height: auto;
    text-align: center;
}

.brandsSection h2,
.productContainer h2 {
    font-weight: 275;
    font-size: clamp(2.5rem, 1.973rem + 2.1622vw, 4rem);
    line-height: 105.2%;
    margin-bottom: 20px;
}

.productContainer h3,
.hiwContainer h3 {
    font-weight: 275;
    font-size: clamp(1.5rem, 1.2632rem + 1.0526vw, 2.25rem);
    line-height: 109.2%;
    /* or 39px */

    color: #3d3939;
}

.brandsSection {
    padding-top: 5%;
    padding-bottom: 5%;
}

.brandsSection button {
    margin: 1rem auto;
}

.brandsSection h3 {
    font-weight: 400;
    font-size: clamp(0.75rem, 0.4737rem + 1.2281vw, 1.625rem);
    line-height: 200%;
    /* or 52px */

    letter-spacing: 0.205em;

    color: #4e7f5e;
    text-transform: uppercase;
}

.logoContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 3rem;
    margin: 5% 0%;
}

.logoContainer > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.logoContainer img {
    width: 100%;
    height: 75px;
    object-fit: contain;
    mix-blend-mode: multiply;
}

.hiwContainer {
    position: relative;
    overflow: hidden;
}

.bgPink {
    height: 80%;
    /* max-width: 70%; */
    z-index: -1;
    position: absolute;
    top: 50%;
    left: -40%;
    transform: translate(-50%, -50%);
}

.benefitsContainer h2,
.hiwContainer h2,
.reviewContainer h2,
.blogContainer h2 {
    font-weight: 275;
    line-height: 105.2%;
    font-size: clamp(2.5rem, 1.973rem + 2.1622vw, 4rem);
    color: #3d3939;
}

.hiwContainer h2 img {
    height: clamp(2rem, 1.1216rem + 3.6036vw, 4.5rem);
    object-fit: contain;
}

.hiwContainer ul {
    margin-top: 2em;
    font-weight: 300;
    line-height: 125%;
    font-size: clamp(1rem, 0.4865rem + 1.0811vw, 1.5rem);
    color: #3d3939;
}

.hiwContainer ul > li {
    margin-bottom: 0.5em;
}

.hiwStep {
    display: flex;
    /* padding: 5%; */
    margin: 2rem 0%;
    justify-content: space-between;
    align-items: center;
}

.hiwStep > * {
    /* flex: 1 1 0%; */
    margin: 5%;
}

.hiwStep > img {
    object-fit: contain;
    width: 30rem;
}

.hiwStep > img:nth-child(odd) {
    margin-left: 0;
}

.hiwStep > img:nth-child(even) {
    margin-right: 0;
}

.hiwStep > div {
    width: 26rem;
    margin: 0 2%;
}

.benefitsContainer {
    overflow-x: hidden;
    font-weight: 300;
    font-size: 1.2rem;
    /* padding-top: 0% 5%; */
}

.quoteContainer {
    position: relative;
}

.quoteContainer h3 {
    position: absolute;
    right: 15%;
    top: 5%;
    font-weight: 400;
    color: #4e7f5e;
    font-size: clamp(1.5rem, 0.7095rem + 3.2432vw, 3.75rem);
    max-width: 60%;
}

.quoteImg {
    z-index: -1;
    width: clamp(20.125rem, 16.2162rem + 16.036vw, 31.25rem);
}

.benefitsContainer .quoteContainer:nth-child(even) .quoteImg {
    margin-top: -20%;
    margin-left: -10%;
}

.benefitsContainer .quoteContainer:nth-child(odd) .quoteImg {
    margin-top: 10%;
    margin-left: 60%;
    transform: scale(-1, -1);
}

.benefitsItem {
    margin: 10% 0% 15%;
}

.benefitsItem h4 {
    font-weight: 800;
    font-size: clamp(0.8rem, 0.5541rem + 1.009vw, 1.5rem);
    line-height: 114%;
    letter-spacing: -0.01em;
    /* Re_Coconut */
    color: #7a6660;
}

.benefitsItem h3 {
    font-weight: 400;
    color: #4e7f5e;
    font-size: clamp(1.5rem, 0.7095rem + 3.2432vw, 3.75rem);
    max-width: 60%;
}

.benefitsItemCardHeader {
    background-color: #ecf2ef;
    margin: 0rem 10% 0rem 0rem;
    padding: 4rem 2rem 4rem;
    letter-spacing: 0.025em;
    font-size: clamp(2.25rem, 1.9865rem + 1.0811vw, 3rem);
    color: #3d3939;
    line-height: 117%;
    font-weight: 275;
}

.benefitsItemCard {
    display: flex;
    background-color: #f5f4f1;
    font-weight: 300;
    font-size: clamp(0.8rem, 0.3784rem + 1.7297vw, 1.2rem);
    line-height: 117%;
    padding: 2rem;
    margin: 0rem 10% 0rem 0rem;
    gap: 1rem;

    /* height: 75%; */
}

.benefitsItemCard > div:first-child {
    /* flex: 3; */
    align-self: center;
    width: 75%;
}

.founderName {
    font-weight: 300;
    font-size: clamp(0.625rem, 0.4054rem + 0.9009vw, 1.25rem);
    line-height: 120%;

    color: #4e7f5e;
}

.founderImg {
    width: clamp(100px, 20%, 300px);
    object-fit: contain;
    margin-bottom: -20%;
    margin-right: -15%;
}

.sustainabilityContainer {
    background: #f9f9f9;
    padding-top: 10%;
    padding-bottom: 10%;

    font-weight: 275;
    font-size: clamp(1rem, 0.6486rem + 1.4414vw, 1.5rem);
    line-height: 127%;
    color: #7e7e7e;
}

.sustainabilityContainer h3 {
    font-weight: 300;
    font-size: clamp(2.875rem, 2.5676rem + 1.2613vw, 3.75rem);
    line-height: 117%;
    color: #4e7f5e;

    max-width: 500px;
}

.sustainabilityDetails {
    display: flex;
    gap: 10%;

    font-weight: 275;
    font-size: 20px;
    line-height: 117%;

    color: #3d3939;
}

.sustainabilityDetails > * {
    flex: 1;
}

.sustainabilityDetails hr {
    margin-top: 1rem !important;
    height: 0.5px !important;
    background-color: black;
}

.sustainabilityContainer h4 {
    font-weight: 275;
    font-size: 48px;
    line-height: 60px;
    color: #4e7f5e;
}

.reviewContainer {
    padding-top: 10%;
    padding-bottom: 10%;
    position: relative;
    overflow: hidden;
}

.reviewContainer > img {
    position: absolute;
    z-index: -1;
    width: 80%;
    margin-top: 5%;
    left: 25%;
}

.reviewCard {
    display: flex;
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.1));
    min-height: 350px;
    background: #f9f9f9;

    flex: 0 0 100%;
    scroll-snap-align: start;
    /* width: calc(60% + 50px); */
    width: clamp(250px, 50vw, 650px);

    font-weight: 275;
    font-size: 20px;
    line-height: 127%;
    /* or 127% */

    color: #3d3939;
}

.reviewCard img {
    height: 100%;
    width: 30%;
    object-fit: cover;
}

.reviewCard > *:last-child {
    padding: 5%;
}

.prQuote img {
    display: block;
    margin: 2rem 0rem;
    object-fit: contain;
    /* width: 100%; */
    height: 2rem;
    max-width: 100%;
}

.prContainer {
    padding-top: 10%;
    padding-bottom: 10%;
}

.prQuote {
    max-width: 80%;
    font-size: clamp(1.5rem, 0.973rem + 2.1622vw, 3rem);
    font-weight: 275;
    line-height: 104%;
    color: #3d3939;
    letter-spacing: 0.02em;
    margin-bottom: 5%;
}

.prContainer > .prQuote:nth-child(odd) {
    margin-left: 20%;
}

.blogContainer {
    padding-top: 5%;
    padding-bottom: 5%;
}

.blogCard {
    margin: 2rem;
    width: clamp(200px, 20vw, 450px);
    flex: 0 0 30%;
    /* height: 200px; */
    /* border-radius: 7px; */
    /* filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.1)); */
    /* background: #f9f9f9; */
    overflow: hidden;
}

.blogCard a {
    font-weight: 400;
    font-size: 1rem;
    line-height: 100%;

    letter-spacing: 0.15em;
    text-decoration: none;

    color: #4e7f5e;
}

.blogCard h5 {
    font-weight: 400;
    font-size: 24px;
    line-height: 119%;
    color: #3d3939;

    margin: 2rem 0;
    flex: 0 0 100%;

    /* min-height: 200px; */
}

.blogCard img {
    object-fit: cover;
    height: 200px;
    object-position: center;
}

.faqContainer {
    background: #f2f2f2 !important;
    padding-top: 10%;
    padding-bottom: 10%;
}

.faqContainer .faqFlexContainer {
    display: flex;
    gap: 50px;
    align-items: flex-start;
    justify-content: space-between;
}

.faqContainer img {
    width: 30%;
    max-width: 295px;
    height: auto;
    object-fit: cover;
}

.faqContainer h2 {
    font-weight: 300;
    font-size: clamp(2rem, 1.5327rem + 1.9171vw, 3.33rem);
    line-height: 117%;
    color: #3d3939;
}

.faqContainer p {
    font-weight: 300;
    font-size: clamp(1rem, 0.6486rem + 1.4414vw, 2rem);
    line-height: 111%;
    color: #3d3939;
}

.faqContainer .heading {
    font-weight: 300;
    font-size: clamp(0.875rem, 0.4797rem + 1.6216vw, 2rem);
    line-height: 85%;
    color: #4e7f5e;
}

.horizontalRule {
    width: 100%;
    height: 0px;
    color: #4e7f5e;
    border-top: 0.5px solid #4e7f5e;
    margin-bottom: 10px;
}

.accordionItem {
    background-color: RED !important;
}

.nbsp {
    display: inline;
}

.productImage {
    width: 80%;
    object-fit: contain;
}

.sustainImage {
    flex-grow: 0;
    width: 35%;
    object-fit: contain;
}
.newsImage {
    width: 35%;
    object-fit: contain;
    margin-left: 65%;
    margin-top: -28%;
    text-align: right;
}

/* Mobile Changes */
@media (max-width: 540px) {
    .heroSection {
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
    }
    .heroSection h1 {
        font-size: clamp(1.5rem, 0.5rem + 5.045vw, 5rem);
    }
    .heroSection:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 40rem;
        width: 100vw;
        background: linear-gradient(180deg, #ffffff 32.52%, rgba(255, 251, 251, 0) 89.25%);
        transform: rotate(-180deg);
        z-index: -1;
    }
    .hiwContainer {
        padding-left: 2rem;
    }
    .hiwContainer h3 {
        font-weight: 400;
    }
    .hiwStep {
        align-items: flex-start;
    }
    .hiwStep > div {
        width: 18rem;
    }
    .hiwStep > img {
        width: 15rem;
        margin-left: 0;
    }
    .productContainer {
        flex-direction: column;
    }

    .productContainer > div {
        margin: 5%;
    }

    .hiwContainer > .hiwStep:nth-child(odd) {
        flex-direction: column;
    }

    .hiwContainer > .hiwStep:nth-child(even) {
        flex-direction: column-reverse;
    }

    .sustainabilityDetails {
        flex-direction: column;
    }

    .faqContainer .faqFlexContainer {
        display: block;
    }

    .faqContainer img {
        display: none;
    }

    .reviewContainer {
        padding: 10% 0 0 10%;
    }

    .reviewContainer > img {
        left: 25%;
        width: 120%;
    }

    .reviewCard {
        flex-direction: column;
    }

    .reviewCard img {
        max-height: 250px;
        width: 100%;
    }
    .nbsp {
        display: none;
    }
    .benefitsContainer h2 {
        /* font-size: calc(1.345rem + 1.14vw); */
        font-size: clamp(1.8rem, 1.345rem + 1.14vw, 4rem);
    }
    .sustainImage {
        width: 80%;
        align-self: center;
    }
    .heroSection > div:first-child {
        max-width: 80% !important;
    }
    .blogCard {
        flex: 0 0 60%;
    }
}

@keyframes arrow {
    from {
        opacity: 0;
        transform: translate(-50%, 50%);
    }
    to {
        opacity: 1;
        transform: translate(0, 0);
    }
}
