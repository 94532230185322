.heading {
    font-weight: 400;
    font-size: clamp(0.75rem, 0.3986rem + 1.4414vw, 1.75rem) !important;
    line-height: 143%;
    color: #4e7f5e;
}

.content {
    font-weight: 300;
    font-size: clamp(0.625rem, 0.3176rem + 1.2613vw, 1.5rem) !important;
    line-height: 129%;
    color: #3d3939;
}

.orderStatusContent {
    font-size: 16px !important;
    color: #3d3939;
    font-weight: 300;
    line-height: 130%;
}

.orderStatusHeading {
    font-size: 19px !important;
    color: #4e7f5e;
    font-weight: 500;
    line-height: 130%;
}
