.primaryButton {
    background: #4e7f5e;
    border-radius: 5px;
    color: #ffffff;
    line-height: unset;
    font-weight: 600;
    /* letter-spacing: 0.205em; */
    padding: 0.75em 1.5em;
    /* text-transform: uppercase; */
    font-size: clamp(0.75rem, 0.4865rem + 1.0811vw, 1.25rem);
    border: #4e7f5e 1px solid;
}

.secondaryButton {
    border: 1px solid #4e7f5e;
    border-radius: 5px;
    letter-spacing: 0.205em;
    color: #4e7f5e;
    font-weight: 400;
    line-height: unset;
    padding: 0.75em 1.5em;
    text-transform: uppercase;
    background: unset;
    font-size: clamp(0.75rem, 0.4865rem + 1.0811vw, 1.5rem);
}

.secondaryButtonSmall {
    border: 1px solid #4e7f5e;
    border-radius: 5px;
    letter-spacing: 0.205em;
    color: #4e7f5e;
    font-weight: 400;
    line-height: 1.1em;
    padding: 0.75em 1.5em;
    text-transform: uppercase;
    background: unset;
    font-size: clamp(0.6rem, 0.4875rem + 0.3333vw, 0.8rem);
}

.primaryButton:disabled,
.secondaryButtonSmall:disabled,
.secondaryButton:disabled {
    color: #2e2e2e;
    border: #2e2e2e 1px solid;
}

.secondaryButton:hover:enabled,
.secondaryButtonSmall:hover:enabled {
    transition: 0.3s;
    background-color: #4e7f5e;
    color: white;
}

.primaryButton:hover:enabled {
    transition: 0.3s;
    background-color: white;
    color: #4e7f5e;
    border: #4e7f5e 1px solid;
}
@media (max-width: 540px) {
    .secondaryButton {
        margin-top: 2.5rem;
        margin-bottom: 4.625rem;
    }
}
