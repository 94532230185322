.footer {
    height: 100%;
    background: #4e7f5e;
    color: white;
    padding: 5%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 2rem;
    justify-content: space-evenly;
    font-size: clamp(0.55rem, 0.6rem + 0.505vw, 1rem);
}

.footer h3 {
    text-transform: uppercase;
    color: white;
    letter-spacing: 0.06em;
    font-weight: 700;
    font-size: clamp(0.875rem, 0.4865rem + 1.0811vw, 1.5rem);
    margin-bottom: 1.5em;
}

.footer a {
    font-size: clamp(0.75rem, 0.6182rem + 0.5405vw, 1.125rem);
    font-weight: 400;
    line-height: 100%;
    color: white;
    text-decoration: none;
}

.footer a:hover {
    color: white;
    text-decoration: underline;
}
