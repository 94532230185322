.arrow {
    width: 2.5rem;
    height: 2.5rem;
    color: #4e7f5e;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    z-index: 1;
    background-color: white;
    box-shadow: 0 0 20px 0 lightgray;
    border-radius: 50%;
    padding: 0 1rem;
    cursor: pointer;
}
.scrollContainer {
    display: flex;
    overflow-x: scroll;
    gap: 20px;
    scroll-snap-type: x mandatory;
    padding: 20px 30px;
    overflow-y: visible;
    position: relative;
    scroll-behavior: smooth;
}

.scrollContainer::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: none;
}
.scrollContainer::-webkit-scrollbar-thumb {
    width: 0.5rem;
    height: 1rem;
    border-radius: 0.2rem;
    background-color: #3d3939;
}
.none {
    opacity: 0;
}
@media (max-width: 540px) {
    .arrow {
        display: none;
    }
}
